// Generated by Framer (b0998f3)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Input_WithoutLabel from "./xZ83gbfMe";
const Input_WithoutLabelFonts = getFonts(Input_WithoutLabel);

const serializationHash = "framer-GHHDx"

const variantClassNames = {Swmj0FdJE: "framer-v-1pkn3lk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "Swmj0FdJE", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1pkn3lk", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Swmj0FdJE"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><ComponentViewportProvider ><motion.div className={"framer-1xwktw5-container"} layoutDependency={layoutDependency} layoutId={"aEjbA3WHE-container"}><Input_WithoutLabel email={"Email"} height={"100%"} id={"aEjbA3WHE"} layoutId={"aEjbA3WHE"} style={{width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GHHDx.framer-10v9u9q, .framer-GHHDx .framer-10v9u9q { display: block; }", ".framer-GHHDx.framer-1pkn3lk { height: 200px; position: relative; width: 200px; }", ".framer-GHHDx .framer-1xwktw5-container { flex: none; height: auto; left: 4px; position: absolute; top: 4px; width: 330px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framery76OyswJ7: React.ComponentType<Props> = withCSS(Component, css, "framer-GHHDx") as typeof Component;
export default Framery76OyswJ7;

Framery76OyswJ7.displayName = "Divorce Planner";

Framery76OyswJ7.defaultProps = {height: 200, width: 200};

addFonts(Framery76OyswJ7, [{explicitInter: true, fonts: []}, ...Input_WithoutLabelFonts], {supportsExplicitInterCodegen: true})